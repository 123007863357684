import React from 'react';
import useStyles from './useStyles';
import Card from '../../components/Card'
import Grid from '@mui/material/Grid';


const HomePage = () => {
    const classes = useStyles();
    
    return(
        <div>
            <Grid container spacing={1} alignItems="center" justifyContent="center">
                <Grid item xs="auto">
                    <Card />
                </Grid>
                <Grid item xs="auto">
                    <Card />
                </Grid>
                <Grid item xs="auto">
                    <Card />
                </Grid>
                <Grid item xs="auto">
                    <Card />
                </Grid>
                <Grid item xs="auto">
                    <Card />
                </Grid>
                <Grid item xs="auto">
                    <Card />
                </Grid>
                <Grid item xs="auto">
                    <Card />
                </Grid>
                <Grid item xs="auto">
                    <Card />
                </Grid>
                <Grid item xs="auto">
                    <Card />
                </Grid>
                <Grid item xs="auto">
                    <Card />
                </Grid>
                <Grid item xs="auto">
                    <Card />
                </Grid>
                <Grid item xs="auto">
                    <Card />
                </Grid>
            </Grid>
        </div>
    )
}

export default HomePage;